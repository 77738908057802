<app-layout-header headerType="admin"></app-layout-header>

<mat-drawer-container class="example-container" [hasBackdrop]="true">
	<mat-drawer #drawer [mode]="'over'" position="end"
		><app-quick-view
			*ngIf="selectedAdjournmentUid"
			[selectedAdjournmentUid]="selectedAdjournmentUid"
			(closeQuickView)="onCloseQuickView()"
		></app-quick-view
	></mat-drawer>
	<mat-drawer-content class="dashboard-height">
		<section class="uk-section uk-padding-remove">
			<div
				class="uk-section uk-container uk-container-medium uk-padding-remove"
			>
				<BR />
				<div *ngIf="hasNoSecurityRoles || hasNoSecurityRoleForLocation">
					<table class="uk-table uk-table-divider goa-section">
						<tbody>
							<tr>
								<td class="uk-table-shrink header-danger">
									<mat-icon>warning_amber</mat-icon>
								</td>
								<td class="header-danger-background">
									<h3
										class="uk-text-bold"
										*ngIf="userLocation != null"
									>
										No permissions for {{ userLocation }}
									</h3>
									<h3
										class="uk-text-bold"
										*ngIf="userLocation == null"
									>
										No permissions for All Locations
									</h3>
									<p>
										Your CASS/JOIN user ID currently has
										permission to process adjournment
										requests for the location(s):
										<b>{{ clerkSecurityRoles }}</b
										>. Please confirm the user ID and/or
										dashboard court location is set
										correctly in your
										<a [routerLink]="['/users/my-account']"
											>My Account, User Settings</a
										>. If the issue persists, please ask
										your manager/supervisor to contact the
										JSG Service Desk at 780-427-6957 or
										<a
											href="mailto:jsg-imt-supportdesk@gov.ab.ca"
											><u
												>jsg-imt-supportdesk@gov.ab.ca</u
											></a
										>
										to have a court location assigned to
										your CASS/JOIN user ID.
									</p>
								</td>
							</tr>
						</tbody>
					</table>
				</div>

				<div style="display: flex; width: 100%">
					<div>
						<h2 data-cy="pageHeader" *ngIf="userLocation != null">
							Dashboard - {{ userLocation }}
						</h2>
						<h2 data-cy="pageHeader" *ngIf="userLocation == null">
							Dashboard - All Locations
						</h2>
					</div>
					<div style="margin-left: auto">
						<mat-form-field appearance="outline">
							<input
								matInput
								data-cy="searchAdjournments"
								placeholder=""
								[(ngModel)]="searchTerm"
								#searchField
							/>
							<mat-icon *ngIf="!searching" matSuffix
								>search</mat-icon
							>
							<mat-icon
								*ngIf="searching"
								matSuffix
								(click)="clearSearchTerm($event)"
								class="uk-button"
								>clear</mat-icon
							>
						</mat-form-field>
					</div>
				</div>
				<div>
					<mat-tab-group
						*ngIf="!searching"
						animationDuration="0ms"
						[(selectedIndex)]="activeTabIndex"
						(selectedIndexChange)="changeTab($event)"
					>
						<mat-tab>
							<ng-template mat-tab-label>
								<b>Review Pending</b>
							</ng-template>
							<div
								class="adj-review-list"
								[ngClass]="
									adjournmentsForReview?.length === 0
										? 'adj-review-list-no-result'
										: 'adj-review-list-result'
								"
							>
								<app-adj-list
									*ngIf="isUserLocationLoaded"
									[currentUser]="currentUser"
									[hasNoSecurityRoleForLocation]="
										hasNoSecurityRoleForLocation
									"
									[hasNoSecurityRoles]="hasNoSecurityRoles"
									[adjournmentReview]="adjournmentsForReview"
									[userLocationIdentifier]="userLocationId"
									(openQuickView)="onOpenQuickView($event)"
								>
								</app-adj-list>
							</div>
							<div *ngIf="adjournmentsForReview?.length == 0">
								<app-no-results></app-no-results>
							</div>
						</mat-tab>
						<mat-tab>
							<ng-template mat-tab-label>
								<b>Completed</b>
							</ng-template>
							<div
								style="margin: 10px 0px"
								*ngIf="completedAdjournments?.length > 0"
							>
								<app-completed-requests-listing
									[currentUser]="currentUser"
									[hasNoSecurityRoleForLocation]="
										hasNoSecurityRoleForLocation
									"
									[hasNoSecurityRoles]="hasNoSecurityRoles"
									[adjournmentCompleted]="
										completedAdjournments
									"
									(openQuickView)="onOpenQuickView($event)"
								>
								</app-completed-requests-listing>
							</div>
							<div *ngIf="completedAdjournments?.length == 0">
								<app-no-results></app-no-results>
							</div>
						</mat-tab>
					</mat-tab-group>

					<mat-tab-group *ngIf="searching" animationDuration="0ms">
						<mat-tab>
							<ng-template mat-tab-label>
								<b>Search Results</b>
							</ng-template>
							<div style="margin: 20px 0px">
								<app-request-search-results
									[currentUser]="currentUser"
									[hasNoSecurityRoleForLocation]="
										hasNoSecurityRoleForLocation
									"
									[hasNoSecurityRoles]="hasNoSecurityRoles"
									[searchTerm]="searchTerm"
									[searchResults]="searchResults"
									(openQuickView)="onOpenQuickView($event)"
								>
								</app-request-search-results>
							</div>
						</mat-tab>
					</mat-tab-group>
				</div>
			</div>
		</section>
	</mat-drawer-content>
</mat-drawer-container>

<!-- Notification modal -->
<div id="notification-prompt" uk-modal class="uk-padding-remove">
	<div class="uk-modal-dialog">
		<div
			style="
				height: 5px;
				background-color: #005daa;
				width: auto;
				display: block;
				margin: 0 auto;
			"
		>
			&nbsp;
		</div>
		<div class="uk-modal-body">
			<div uk-grid>
				<div class="uk-width-auto">
					<img src="/favicon.ico" />
				</div>
				<div class="uk-width-expand">
					<strong
						>Want to be notified when new requests come in?</strong
					>
					<p>
						You can allow the system to notify you when a new
						adjournment request comes in and is ready for review.
						You can turn off notifications at anytime with your
						browser settings.
					</p>
				</div>
			</div>
			<div class="uk-float-right" uk-grid>
				<div>
					<button
						class="uk-button uk-border-rounded button-secondary uk-modal-close"
						type="button"
						(click)="notificationPrompt(false)"
					>
						No, thanks
					</button>
				</div>
				<div>
					<button
						class="uk-button uk-border-rounded button-primary uk-modal-close"
						type="button"
						(click)="notificationPrompt(true)"
					>
						Yes, please
					</button>
				</div>
			</div>
		</div>
	</div>
</div>

<!-- Popup modal -->
<div id="popup-prompt" uk-modal class="uk-padding-remove">
	<div class="uk-modal-dialog">
		<div
			style="
				height: 5px;
				background-color: #005daa;
				width: auto;
				display: block;
				margin: 0 auto;
			"
		>
			&nbsp;
		</div>
		<div class="uk-modal-body">
			<div uk-grid>
				<div class="uk-width-auto">
					<img src="/favicon.ico" />
				</div>
				<div class="uk-width-expand">
					<p>
						<strong
							>Allow endorsement sheet to show after granting an
							adjournment</strong
						>
						&nbsp;

						<a
							(click)="onClosePopupMessage()"
							style="color: #666666"
						>
							<mat-icon>close</mat-icon>
						</a>
					</p>
					<p>
						You must allow ADS to show the endorsement sheet in a
						new browser window right after you endorse the
						adjournment request.
					</p>
					<p>
						To allow this, please click the 'pop-up blocked'-icon in
						the right of the URL bar (see picture below) and select
						'Always allow pop-ups and redirects from ADS.'
					</p>
					<img src="/assets/images/pop-up-blocked.jpg" />
				</div>
			</div>
		</div>
	</div>
</div>
