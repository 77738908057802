import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticatedUserProfileKeycloakModel } from '@adj/shared/authentication/models/authenticationed-user-keycloak.model';
import { AuthenticationService } from '@adj/shared/authentication/services/authentication.service';
import { EnvConfigService } from '@adj/common/services/env-config.service';
import { VersionService } from '@adj/generators/services/version.service';
import { catchError, tap } from 'rxjs/operators';

@Component({
	selector: 'app-layout-header',
	templateUrl: './layout-header.component.html',
	styleUrls: ['./layout-header.component.scss'],
})
export class LayoutHeaderComponent implements OnInit {
	@Input() headerType: string = 'default';

	public showBanner: boolean = true;
	public environment: string = 'loading';
	public currentUser: AuthenticatedUserProfileKeycloakModel;

	constructor(
		private authenticationService: AuthenticationService,
		private _versionService: VersionService,
		private configService: EnvConfigService,
		private router: Router
	) {}

	async ngOnInit() {
		if (this.headerType != 'public') {
			this.currentUser =
				await this.authenticationService.getCurrentUser();
		}

		this.handleBanner();
	}

	closeBanner() {
		sessionStorage.setItem('banner-environment', 'true');
		this.showBanner = false;
		return false;
	}

	private handleBanner() {
		var configEnvironment = this.configService.get('environment');
		if (configEnvironment.toLowerCase() != 'production') {
			//get the environment from API
			this._versionService
				.getEnvironment()
				.pipe(
					tap((result) => {
						this.environment = result.toUpperCase();
					}),
					catchError((error: any): any => {
						this.environment = 'unknown';
					})
				)
				.subscribe();
		} else this.showBanner = false;
	}
}
